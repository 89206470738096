import React, { useState } from "react"
import { Form, Col, Button, Alert, Spinner } from "react-bootstrap"
import { useFormik } from "formik"
import * as yup from "yup"
import { FormattedMessage } from "react-intl"

const EmailForm = ({ deVersion, position }) => {
  const [files, setFiles] = useState([])
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const positionValue = position

  const errorTranslationObject = deVersion
    ? {
        min: "Muss mindenstens 1 Zeichen lang sein.",
        max: "Muss maximal 20 Zeichen lang sein",
        email: "Ungültige E-Mail-Adresse",
        required: "Pflichtfeld",
        checkFileSize:
          "ist zu groß, bitte wählen Sie eine kleinere Datei. Maximale Dateigröße ist 15 MB\n",
        maxSelectFile:
          "Es können nur 3 Dateien gleichzeitig hochgeladen werden.",
      }
    : {
        min: "Must be at least 1 characters long",
        max: "Must be 20 characters or less",
        email: "Invalid email address",
        required: "Required",
        checkFileSize:
          "is too large, please pick a smaller file. Maximum file size per file is 15 MB\n",
        maxSelectFile: "Only 3 files can be uploaded at a time",
      }

  const maxSelectFile = event => {
    let files = event.target.files
    if (files.length > 3) {
      const msg = errorTranslationObject.maxSelectFile
      event.target.value = null // discard selected file
      setError(msg)
      return false
    }
    return true
  }

  const checkFileSize = event => {
    let files = event.target.files
    let size = 15000000
    let error = ""
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        error += files[x].type + errorTranslationObject.maxSelectFile
      }
    }
    if (error !== "") {
      event.target.value = null
      setError(error)
      return false
    }
    return true
  }

  const onChangeUploadHandler = event => {
    let files = event.target.files
    if (maxSelectFile(event) && checkFileSize(event)) {
      setFiles(files)
    }
  }

  const schema = yup.object({
    firstName: yup
      .string()
      .min(1, errorTranslationObject.min)
      .max(20, errorTranslationObject.max)
      .required(errorTranslationObject.required),
    lastName: yup
      .string()
      .min(1, errorTranslationObject.min)
      .max(20, errorTranslationObject.max)
      .required(errorTranslationObject.required),
    email: yup
      .string()
      .email(errorTranslationObject.email)
      .required(errorTranslationObject.required),
    phoneNumber: yup
      .string()
      .min(1, errorTranslationObject.min)
      .max(15, errorTranslationObject.max),
    url: yup.string().min(1, errorTranslationObject.min),
  })
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      url: "",
    },
    validationSchema: schema,
    onSubmit: async values => {
      let formData = new FormData()
      formData.append("firstName", values.firstName)
      formData.append("lastName", values.lastName)
      formData.append("email", values.email)
      formData.append("phoneNumber", values.phoneNumber)
      formData.append("url", values.url)
      formData.append("position", positionValue)
      for (var i = 0; i < files.length; i++) {
        formData.append("file", files[i])
      }
      if (formData) {
        setLoading(true)
        // eslint-disable-next-line
        const res = await fetch("/api/sendRecruitmentMail", {
          method: "POST",
          body: formData,
        })
          .then(response => {
            if (response.status === 200) {
              setSuccess(true)
              setLoading(false)
            }
          })
          .catch(error => {
            console.log(error)
            setError(error)
            setLoading(false)
          })
      }
    },
  })
  return (
    <div>
      <h2>
        <FormattedMessage id="carreerForm" />
      </h2>
      <div style={{ textAlign: "center" }}>
        {loading && !success ? (
          <Spinner animation="border" variant="primary" />
        ) : null}
      </div>
      {!loading && success ? (
        <Alert variant="success">
          <FormattedMessage id="carreerSuccessMessage" />
        </Alert>
      ) : null}
      {!success && !loading ? (
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          encType="multipart/form-data"
        >
          <Form.Group as={Col} controlId="firstName">
            <Form.Label>
              <FormattedMessage id="carreerFirstName" />
            </Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              placeholder="Max"
              {...formik.getFieldProps("firstName")}
              isValid={formik.touched.firstName && !formik.errors.firstName}
              isInvalid={formik.touched.firstName && formik.errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="lastName">
            <Form.Label>
              <FormattedMessage id="carreerLastName" />
            </Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              placeholder="Mustermann"
              {...formik.getFieldProps("lastName")}
              isValid={formik.touched.lastName && !formik.errors.lastName}
              isInvalid={formik.touched.lastName && formik.errors.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="max.mustermann@mail.com"
              name="email"
              {...formik.getFieldProps("email")}
              isValid={formik.touched.email && !formik.errors.email}
              isInvalid={formik.touched.email && formik.errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="phoneNumber">
            <Form.Label>
              <FormattedMessage id="carreerTel" />
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="+43 01 123456789"
              name="phoneNumber"
              {...formik.getFieldProps("phoneNumber")}
              isInvalid={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.phoneNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="url">
            <Form.Label>
              <FormattedMessage id="carreerLinkedIn" />
            </Form.Label>
            <Form.Control
              name="url"
              type="text"
              {...formik.getFieldProps("url")}
              placeholder="Github, LinkedIn or Homepage URL"
              isInvalid={formik.touched.url && formik.errors.url}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.url}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="files">
            <Form.Label>
              <FormattedMessage id="carreerCV" />
            </Form.Label>
            <Form.Control
              required
              multiple
              name="files"
              type="file"
              onChange={event => {
                onChangeUploadHandler(event)
              }}
              accept="image/jpg, image/jpeg, image/png, .pdf, .doc, .docx, application/msword"
              isInvalid={error}
            />
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
            <Form.Text>
              <FormattedMessage id="carreerAllowDataTypes" />
            </Form.Text>
          </Form.Group>
          <Form.Group as={Col} style={{ textAlign: "center" }}>
            <Button type="submit">
              <FormattedMessage id="carreerApplyNow" />
            </Button>
          </Form.Group>
        </Form>
      ) : null}
    </div>
  )
}

export default EmailForm
