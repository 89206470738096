// Import SVGs
import daskLogo from "./dask.svg"
import djangoLogo from "./django.svg"
import dockerLogo from "./docker.svg"
import onnxaiLogo from "./onnxai.svg"
import opencvLogo from "./opencv.svg"
import postgresqlLogo from "./postgresql.svg"
import pythonLogo from "./python.svg"
import pytorchLogo from "./pytorch.svg"
import reactjsLogo from "./reactjs.svg"
import redisLogo from "./redis.svg"

const TechstackLogos = [
  { img: daskLogo, alt: "Dask Logo" },
  { img: djangoLogo, alt: "Dask Logo" },
  { img: dockerLogo, alt: "Dask Logo" },
  { img: onnxaiLogo, alt: "Dask Logo" },
  { img: opencvLogo, alt: "Dask Logo" },
  { img: postgresqlLogo, alt: "Dask Logo" },
  { img: pythonLogo, alt: "Dask Logo" },
  { img: pytorchLogo, alt: "Dask Logo" },
  { img: reactjsLogo, alt: "Dask Logo" },
  { img: redisLogo, alt: "Dask Logo" },
]

export default TechstackLogos
