import React from "react"
import { Row, Container } from "react-bootstrap"

import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/scss/swiper.scss"

// Import SVGs
// import TechstackLogos from "./TechstackLogos/TechstackLogos.js"

import style from "./style.module.scss"

const LoopModeWithMultipleSlidesPerGroup = ({ logos }) => {
  console.log(logos)
  const params = {
    slidesPerView: 1,
    spaceBetween: 10,
    slidesPerGroup: 1,
    loop: true,
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
    },
    // navigation: {
    //   nextEl: ".swiper-button-next.swiper-button-blue",
    //   prevEl: ".swiper-button-prev.swiper-button-blue",
    // },
    centeredSlides: true,
    breakpoints: {
      768: {
        slidesPerView: 5,
        spaceBetween: 20,
        slidesPerGroup: 1,
      },
      500: {
        slidesPerView: 1,
        spaceBetween: 10,
        slidesPerGroup: 1,
      },
    },
  }
  return (
    <section className="grey-background">
      <Container>
        <Row>
          <Swiper {...params}>
            {logos.map(logo => (
              <div className={style.slide}>
                <img src={logo.img} width={140} height={100} alt={logo.alt} />
              </div>
            ))}
          </Swiper>
        </Row>
      </Container>
    </section>
  )
}
export default LoopModeWithMultipleSlidesPerGroup
